@import "styles/variables";

.wrapper {
  picture, img{
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }  
}
