$footer-color: #0c151d;

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-radius: 15px;
$general-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;

$brand-color : #aaa;
$discount-color: rgb(230, 59, 69);
$new-color: #62fc7f;
$status-color: rgb(255, 199, 15);
$favourite-color: #50c7f7;
$gift-color: #db1ec2;

$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: #0c151d;
$primary-color-hover: #13212c;

$secondary-color: #0c151d;

$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$grey-border-color: #f0f0f0;